// Gallery.jsx
import React from 'react';
import Layout from "../components/layout";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import {StaticImage} from "gatsby-plugin-image";

const GalleryJapan1994 = () => {
    return (
        <Layout>
            <a href="/Gallery">Return to Gallery</a>
            <h1>Japan 1974 - 1987</h1>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Japan1974/1056501_orig.jpg"
                             alt="Sensei Higaonna and Sensei Graham Ravey at Yoyogi Dojo party"/>
                <StaticImage src="../images/gallery/Japan1974/1725456_orig.jpg"
                             alt="South African team with Sensei Higaonna, Steve and Sensei Ravey. © Harry Cook"/>
                <StaticImage
                    src="../images/gallery/Japan1974/175655_orig.jpg"
                    alt="Visit to Yoyogi Dojo by Miyagi Anichi Sensei, with Morio Higaonna Sensei. 1977"/><StaticImage
                src="../images/gallery/Japan1974/1815520_orig.jpg" alt='Sensei Ravey, "The Swede" and Steve Bellamy training
                                                                                                 with Japanese
                                                                                                 Team 1977'/>
                <StaticImage src="../images/gallery/Japan1974/2211715_orig.jpg"
                             alt="Hojo undo (power training) in Yoyogi Dojo"/>
                <StaticImage src="../images/gallery/Japan1974/2364625_orig.jpg"
                             alt="Sensei Steve Bellamy in Tokyo Practicing Kata"/>
                <StaticImage src="../images/gallery/Japan1974/2448990_orig.jpg"
                             alt="Graham as Lord Beathan in the program 'Photon' in Japan, supplied by Paul Hemme"/>
                <StaticImage src="../images/gallery/Japan1974/2933332_orig.jpg"
                             alt="Young Sensei Higaonna at Yoyogi Dojo Performing Kata"/>
                <StaticImage src="../images/gallery/Japan1974/3116034_orig.jpg"
                             alt="Bruce Anderson, Graham Ravey, Liam Keeley (L to R)"/>
                <StaticImage src="../images/gallery/Japan1974/3348223_orig.jpg"
                             alt="Terauchi Sensei (front), Sensei Ravey second from right with injured knee"/>
                <StaticImage src="../images/gallery/Japan1974/3375307_orig.jpg"
                             alt="Sensei Ravey taking an afternoon session at Yoyogi Dojo in the absence of Sensei Higaonna"/>
                <StaticImage src="../images/gallery/Japan1974/3547296_orig.jpg"
                             alt="Foreigners at Yoyogi Dojo, Front row - Liam Keely, Sensei Graham Ravey, Bruce Anderson."/>
                <StaticImage src="../images/gallery/Japan1974/3662788_orig.jpg" alt="Sensei Terauchi IOGKF 1978"/>
                <StaticImage src="../images/gallery/Japan1974/3798453_orig.jpg"
                             alt="Yoyogi team at the Shibuya-ku annual karate tournament: Sensei Higaonna (front row, fourth from right), Sensei Ravey (tallest man on back row)"/>
                <StaticImage src="../images/gallery/Japan1974/4324178_orig.jpg" alt=""/>
                <StaticImage src="../images/gallery/Japan1974/5353169_orig.jpg"
                             alt="Sensei Kokubo Uchedeshi at Yoyogi Dojo 1976"/>
                <StaticImage src="../images/gallery/Japan1974/6675524_orig.jpg"
                             alt="Sensei Higaonna and Sensei Ravey at Yoyogi Dojo"/>
                <StaticImage src="../images/gallery/Japan1974/7072525_orig.jpg"
                             alt="Sensei Graham Ravey and Steve Bellamy(Nidan) outside the Yoyogi Dojo after a workout"/>
                <StaticImage src="../images/gallery/Japan1974/7789577_orig.jpg"
                             alt="Sensei Higaonna practicing Sepai Kata 1975"/>
                <StaticImage src="../images/gallery/Japan1974/8221625_orig.jpg"
                             alt="Sesei Ravey practising in Yoyogi dojo"/>
                <StaticImage src="../images/gallery/Japan1974/8250102_orig.jpg"
                             alt="Sensei Higaonna, Graham Ravey and Bruce Anderson, morning Kata training at Yoyogi Dojo"/>
                <StaticImage src="../images/gallery/Japan1974/8826351_orig.jpg" alt='The
                         Swede", Bert Valentin Johansson'/>
                <StaticImage src="../images/gallery/Japan1974/9027198_orig.jpg" alt="Sensei Ravey at Yoyogi Dojo"/>
                <StaticImage src="../images/gallery/Japan1974/9082208_orig.jpg"
                             alt="Sensei Higaonna and Sensei Graham Ravey at Yoyogi Dojo party"/>
                <StaticImage src="../images/gallery/Japan1974/9111472_orig.jpg"
                             alt='Japanese Team 1977 that won the world Karate Championships in Longbeach, California. Front row "
                         The Swede", Back row Sensei Ravey and Steve Bellamy'/>
                <StaticImage src="../images/gallery/Japan1974/940253_orig.jpg"
                             alt="Graham as Lord Beathan in the program 'Photon' in Japan, supplied by Paul Hemme"/>
            </SRLWrapper></SimpleReactLightbox>
        </Layout>
    );
}

export default GalleryJapan1994;
